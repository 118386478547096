import styled from "@emotion/styled";
import { FiClock } from "react-icons/fi";
import placeholder from "./../../../assets/img/placeholder.svg";
import { NavLink } from "react-router-dom";
import { Article } from "../../../types/Article";
import { IconTextList } from "../../Layout/IconTextList/IconTextList";
import { IconTextItem } from "../../Layout/IconTextList/IconTextItem";
import { formatMinutes } from "../../../helpers/formathelpers";

const BoxWrapper = styled(NavLink)`
    position: relative;
    text-decoration: none;
`;
const ImageWrapper = styled("div")<{
    photo: string;
    contain?: boolean;
}>`
    position: relative;
    width: 100%;
    height: 0;
    padding-top: calc(100% * (126 / 164));
    background-color: ${({ theme }) => theme.colors.white["1000"]};
    background-image: url(${({ photo }) => photo});
    background-size: ${({ contain }) => (contain ? "contain" : "cover")};
    background-position: center;
    background-repeat: no-repeat;
`;
const TextWrapper = styled("div")`
    position: relative;
    padding-top: 8px;
    padding-bottom: 24px;
`;
const CategoryText = styled("p")`
    ${({ theme }) => theme.text.paragraph.tiny}
    position: relative;
    color: ${({ theme }) => theme.colors.primary.gunmetal["640"]};
    margin: 0 0 4px 0;
`;
const PostTitle = styled("h6")`
    ${({ theme }) => theme.text.h6}
    position: relative;
    color: ${({ theme }) => theme.colors.primary.gunmetal["1000"]};
    margin: 0;
    margin-bottom: 8px;
    &:first-of-type {
        margin-top: 4px;
    }
`;

const ClockIcon = styled(FiClock)`
    margin-right: 6px;
`;

interface ArticleListItemProps {
    item: Article;
    contain?: boolean;
}

export const ArticleListItem = (props: ArticleListItemProps) => {
    const { item, contain } = props;

    return (
        <BoxWrapper to={`/article/${item.id}`}>
            <ImageWrapper
                contain={contain}
                photo={item.photo_path !== null ? item.photo_path : placeholder}
            />
            <TextWrapper>
                {item.category && (
                    <CategoryText>{item.category.name}</CategoryText>
                )}
                <PostTitle>{item.title}</PostTitle>
                <IconTextList>
                    {item.time_read && (
                        <IconTextItem
                            text={formatMinutes(item.time_read)}
                            icon={<ClockIcon />}
                        />
                    )}
                </IconTextList>
            </TextWrapper>
        </BoxWrapper>
    );
};
